import type { CustomDomain } from '@playful/api';
import {
  Box,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  OrderedList,
  Text,
} from '@playful/design_system';
import React from 'react';

import { CustomDomainBlock } from './CustomDomainBlock';

export default function CustomDomainSetupDialog({
  isOpen,
  onClose,
  Footer,
  domain,
  hideSetupSteps,
  isPublishing,
  title = 'setup steps',
  ...modalProps
}: {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  hideSetupSteps?: boolean; // hide the link to open the setup steps in the domain block. This is the setup steps
  isPublishing?: boolean;
  domain: CustomDomain;
  modalProps?: ModalProps;
  Footer?: React.ReactNode;
}) {
  // Find the A record for the domain
  const dnsEntry = domain.dnsEntries.find((entry) => entry.record_type === 'A');

  const domainParts = domain.name.split('.');

  const hostName = domainParts.length > 2 ? domainParts.slice(0, -2).join('.') : '@';

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...modalProps}>
      <ModalOverlay />
      <ModalContent maxW='650px' onCopy={(e) => e.stopPropagation()}>
        <ModalHeader pt={10} pb={0} px={10}>
          {title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody m={10} mt={6} p={0}>
          {isPublishing && (
            <CustomDomainBlock
              domain={domain}
              onDelete={onClose}
              hideSetupSteps={true}
              alignBadgeLeft
              mb={3}
            />
          )}
          <Box borderColor='gray.200' borderWidth='1px' borderRadius='md' p={4}>
            <Box mb={5}>
              <Text size='md' color='gray.700' fontWeight='bold'>
                Next steps:
              </Text>
            </Box>
            <Box mb={5}>
              <Text color='gray.700' size='sm'>
                To completely setup your domain to work with Hatch, you must configure your domain
                DNS settings to point the domain to Hatch.
              </Text>
            </Box>
            <Box mb={5}>
              <OrderedList>
                <ListItem>
                  Sign into your registrar account and locate the option to ‘manage DNS’
                </ListItem>
                <ListItem>
                  <Text as='span' fontWeight='bold'>
                    Add a new DNS record:
                  </Text>
                  <Box mt={0} ml={8}>
                    <Box>
                      Host Name:{' '}
                      <Text as='span' fontWeight='bold'>
                        {hostName}
                      </Text>
                    </Box>
                    <Box>
                      Record Type:{' '}
                      <Text as='span' fontWeight='bold'>
                        A
                      </Text>
                    </Box>
                    <Box>
                      Value/Data/Address:{' '}
                      <Text as='span' fontWeight='bold'>
                        {dnsEntry?.value}
                      </Text>
                    </Box>
                    <Box>
                      TTL:{' '}
                      <Text as='span' fontWeight='bold'>
                        3600 (or default)
                      </Text>
                    </Box>
                  </Box>
                </ListItem>
              </OrderedList>
            </Box>
            <Box mb={5}>
              <Text color='gray.700' size='sm'>
                <Text as='span' fontWeight='bold'>
                  Note:
                </Text>{' '}
                It can take up to{' '}
                <Text as='span' fontWeight='bold'>
                  24 hours
                </Text>{' '}
                before your domain is pointed at Hatch by your registrar. During this time, you will
                see a “verifying” status for any project you publish to this domain. Once the
                verifying process is complete, we will take care of the rest and your site will be
                live!
              </Text>
            </Box>
            <Box>
              <Link href='/faqs' textDecor='underline' fontWeight='bold' target={'_blank'}>
                get help
              </Link>
            </Box>
          </Box>
        </ModalBody>
        {Footer}
      </ModalContent>
    </Modal>
  );
}
