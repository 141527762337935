import type { CustomDomain } from '@playful/api';
import {
  Badge,
  Button,
  HStack,
  HStackProps,
  IconButton,
  Text,
  TrashIcon,
} from '@playful/design_system';
import React, { useState } from 'react';

import CustomDomainDeleteDialog from './CustomDomainDeleteDialog';
import CustomDomainSetupDialog from './CustomDomainSetupDialog';

export function CustomDomainBlock({
  domain,
  hideSetupSteps,
  onDelete,
  alignBadgeLeft,
  ...hStackProps
}: {
  domain: CustomDomain;
  hideSetupSteps?: boolean;
  onDelete?: () => void;
  alignBadgeLeft?: boolean;
} & HStackProps) {
  const [selectedDomain, setSelectedDomain] = useState<CustomDomain | null>(null);
  const [showCustomDomainSetupInfo, setShowCustomDomainSetupInfo] = useState(false);
  const [showDeleteCustomDomain, setShowDeleteCustomDomain] = useState(false);

  function handleDeleteCustomDomain(domain: CustomDomain) {
    setShowDeleteCustomDomain(true);
    setSelectedDomain(domain);
  }

  function handleViewSetupSteps(domain: CustomDomain) {
    setShowCustomDomainSetupInfo(true);
    setSelectedDomain(domain);
  }

  return (
    <>
      <HStack justifyContent={'space-between'} {...hStackProps}>
        <HStack>
          <Text color='gray.700' userSelect={'text'}>
            {domain.name}
          </Text>
          {domain.redirect && (
            <Text color='gray.400' fontWeight='medium' fontSize='sm'>
              (redirect to {domain.redirect})
            </Text>
          )}
          {alignBadgeLeft && <StatusBadge status={domain.status} />}
        </HStack>

        <HStack>
          {domain.status.toLowerCase() !== 'active' && (
            <>
              {!hideSetupSteps && (
                <Button
                  variant='link'
                  textDecoration='underline'
                  color='gray.700'
                  fontWeight='medium'
                  fontSize='sm'
                  onClick={() => {
                    handleViewSetupSteps(domain);
                  }}
                >
                  view setup steps
                </Button>
              )}
              {!alignBadgeLeft && <StatusBadge status={domain.status} />}
            </>
          )}
          <IconButton
            variant='ghost'
            size='xs'
            aria-label='delete component'
            icon={<TrashIcon />}
            onClick={() => {
              handleDeleteCustomDomain(domain);
            }}
          />
        </HStack>
      </HStack>

      {showCustomDomainSetupInfo && (
        <CustomDomainSetupDialog
          isOpen={showCustomDomainSetupInfo}
          onClose={() => setShowCustomDomainSetupInfo(false)}
          domain={domain}
        />
      )}
      {showDeleteCustomDomain && selectedDomain && (
        <CustomDomainDeleteDialog
          domain={selectedDomain}
          isOpen={showDeleteCustomDomain}
          onClose={() => {
            setShowDeleteCustomDomain(false);
            setSelectedDomain(null);
          }}
          onDelete={onDelete}
        />
      )}
    </>
  );
}

function StatusBadge({ status }: { status: string }) {
  return (
    <Badge variant='solid' bg='yellow.100' color='gray.700' size='sm'>
      {status === 'PROVISIONING' ? 'verifying' : status.toLowerCase()}
    </Badge>
  );
}
