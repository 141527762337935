import { ModalFooter } from '@chakra-ui/react';
import type { CustomDomain } from '@playful/api';
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
  TrashIcon,
} from '@playful/design_system';
import React, { useState } from 'react';

import { CTAButton } from '../../components/CallToAction/CTAButton';
import { useCustomDomains } from '../useCustomDomains';
import { useUserContext } from '../UserContext';

export function CustomDomainDeleteButton(props: {
  domain: CustomDomain;
  onDeleteClick: (domain: CustomDomain) => void;
  onDeleteConfirm: (domain: CustomDomain) => void;
  onDeleteCancel: (domain: CustomDomain) => void;
}) {
  const { domain, onDeleteClick, onDeleteConfirm } = props;
  const [showDeleteCustomDomain, setShowDeleteCustomDomain] = useState(false);
  function handleDeleteCustomDomain() {
    setShowDeleteCustomDomain(true);
    onDeleteClick(domain);
  }

  return (
    <>
      <IconButton
        variant='ghost'
        size='xs'
        aria-label='delete component'
        icon={<TrashIcon />}
        onClick={handleDeleteCustomDomain}
      />
      {showDeleteCustomDomain && (
        <CustomDomainDeleteDialog
          domain={domain}
          isOpen={showDeleteCustomDomain}
          onClose={() => {
            setShowDeleteCustomDomain(false);
          }}
          onDelete={onDeleteConfirm}
        />
      )}
    </>
  );
}

export default function CustomDomainDeleteDialog({
  domain,
  isOpen,
  onClose,
  onDelete,
  ...modalProps
}: {
  domain: CustomDomain;
  isOpen: boolean;
  onClose: () => void;
  onDelete?: (domain: CustomDomain) => void;
  modalProps?: ModalProps;
}) {
  const { user } = useUserContext();
  const { deleteCustomDomain } = useCustomDomains(user);
  const [isDeleting, setIsDeleting] = useState(false);

  async function handleDelete() {
    setIsDeleting(true);
    await deleteCustomDomain(domain.name);
    setIsDeleting(false);
    onDelete?.(domain);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...modalProps}>
      <ModalOverlay />
      <ModalContent maxWidth='340px'>
        <ModalHeader pt='6'>Delete {domain.name}?</ModalHeader>
        <ModalBody>
          <Text color='gray.700' size='md'>
            Any projects published to this domain will be unpublished. You cannot undo this. Are you
            sure you want to continue?
          </Text>
        </ModalBody>
        <ModalFooter gap={4} pb={6}>
          <Button variant='outline' w='100%' onClick={onClose} borderColor='gray.200'>
            cancel
          </Button>
          <CTAButton colorScheme='red' w={'100%'} isLoading={isDeleting} onClick={handleDelete}>
            delete it
          </CTAButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
