import { FormHelperText, ModalFooter } from '@chakra-ui/react';
import type { CustomDomain } from '@playful/api';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@playful/design_system';
import React, { useState } from 'react';

import CustomDomainSetupDialog from '../user/Account/CustomDomainSetupDialog';
import { useCustomDomains } from '../user/useCustomDomains';
import { useUserContext } from '../user/UserContext';
import { CTAButton } from './CallToAction/CTAButton';

type AddDomainModalProps = {
  isOpen: boolean;
  onClose(): void;
  isPublishing?: boolean;
};

export function AddDomainModal(props: AddDomainModalProps) {
  const { isOpen, onClose, isPublishing = true } = props;
  const [domain, setDomain] = useState<CustomDomain | undefined>();

  function handleSuccess(newDomain: CustomDomain) {
    setDomain(newDomain);
  }

  if (domain) {
    return (
      <CustomDomainSetupDialog
        isOpen={isOpen}
        onClose={onClose}
        domain={domain}
        hideSetupSteps
        title={'connect your domain'}
        isPublishing={isPublishing}
        Footer={
          isPublishing && (
            <ModalFooter>
              <CTAButton onClick={onClose}>return to publishing</CTAButton>
            </ModalFooter>
          )
        }
      />
    );
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size={'3xl'} isCentered>
      <ModalOverlay />
      <ModalContent p={4} pb={8}>
        <ModalHeader fontSize={'2xl'}>connect your domain</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AddDomainForm onSuccess={handleSuccess} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const domainRegex = new RegExp(
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/,
);
function checkIsValidDomain(domain: string) {
  return domain.match(domainRegex);
}

export function AddDomainForm({ onSuccess }: { onSuccess: (domain: CustomDomain) => void }) {
  const { user } = useUserContext();
  const { addCustomDomain } = useCustomDomains(user);
  const [isLoading, setIsLoading] = useState(false);
  const [domainName, setDomainName] = useState('');

  const [error, setError] = useState('');

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true);

    if (!checkIsValidDomain(domainName)) {
      setError('Please enter a valid domain name. For example: example.com');
      setIsLoading(false);
      return;
    }

    const [err, data] = await addCustomDomain(domainName);
    if (!err && data) {
      const addedDomain = data.find((d) => d.name === domainName) ?? data[data.length - 1];
      onSuccess(addedDomain);
    } else if (err) {
      console.error(err);
      if (err?.status === 409) {
        setError('This domain is already in use.');
      } else if (err?.status === 400) {
        setError('Invalid domain. Is it a valid TLD?');
      } else {
        setError(err?.message || err?.statusText || err);
      }
    }
    setIsLoading(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setDomainName(e.target.value);
    setError('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormControl flexGrow={1}>
        <FormLabel color={'gray.500'} fontSize={'sm'} mb={1}>
          your domain name
        </FormLabel>
        <Box display={'flex'} gap={4} alignItems={'baseline'} width={'100%'}>
          <Input
            name={'domainName'}
            placeholder='example.com'
            variant={'filled'}
            required
            value={domainName}
            onChange={handleChange}
          />
          <CTAButton
            minWidth={'144px'}
            px={4}
            color={'gray.700'}
            type={'submit'}
            isLoading={isLoading}
          >
            add domain
          </CTAButton>
        </Box>

        <FormHelperText fontWeight='normal' fontSize={'xs'} color={!!error ? 'red' : undefined}>
          {!!error ? (
            `${error}`
          ) : (
            <>
              enter a domain you own to use for published projects.{' '}
              <Link
                fontWeight={'bold'}
                textDecoration={'underline'}
                href={'/faqs'}
                target={'_blank'}
              >
                learn more
              </Link>
            </>
          )}
        </FormHelperText>
      </FormControl>
    </form>
  );
}
